// i18next-extract-mark-ns-start bizum

import {ContactSalesButton} from 'components/ContactSalesButton';
import {Content} from 'components/Content';
import {IndexBackground} from 'components/landings/IndexBackground';
import {List} from 'components/List';
import {Section, SectionActions, SectionHeader, SectionImage} from 'components/Section';
import {SEO} from 'components/SEO';
import {SignUpButton} from 'components/SignUpButton';
import {PageProps, graphql} from 'gatsby';
import {Trans, useI18next} from 'gatsby-plugin-react-i18next';
import bizum_monei from 'images/bizum_monei.svg';
import like from 'images/like.svg';
import React from 'react';
import {MainTitleContent} from 'components/payment-methods/MainTitleContent';
import {MainTitleImg} from 'components/payment-methods/MainTitleImg';
import {BlogLink} from 'components/links/Blog';
import {InternalPageLink} from 'components/links/Pages';
import CardsContainer from 'components/CardsContainer';
import {Card, CardImage, CardWrapper} from 'components/Card';
import {AnchorLink} from 'components/AnchorLink';
import coinstack from 'images/coinstack.svg';
import cart from 'images/cart.svg';
import click from 'images/click1.svg';
import mobile_payment from 'images/mobile_payment.svg';
import person from 'images/person.svg';
import Bold from 'components/Bold';
import {SupportLink} from 'components/links/Support';
import {AndroidDownloadLink} from 'components/DownloadOnGooglePlay';
import {IosDownloadLink} from 'components/DownloadOnAppleStore';
import {Partners} from 'components/Partners';
import {Background} from 'components/Background';
import moneiPayFormEs from 'images/monei_pay_form_es.png';
import moneiPayFormEn from 'images/monei_pay_form_en.png';
import {GoogleReviews, Place} from 'components/GoogleReviews';
import styled from 'styled-components';
import {FaqsSection} from 'components/FaqsSection';
import {mapCategory} from 'utils/blog-utils';
import {BlogPost} from '../../../types/BlogTypes';
import {getPostsFromPrismicEdges} from 'utils/blog-utils';
import {BlogCategoryType} from '../../../types/BlogTypes';
import {RelatedBlogPosts} from 'components/RelatedBlogPosts';

const ReviewsBackground = styled.div`
  position: relative;
  margin-top: 100px;

  &:before {
    display: block;
    content: '';
    z-index: -1;
    height: 70%;
    position: absolute;
    transform: skew(0, -9deg);
    left: 0;
    right: 0;
    bottom: 0;
    top: 20%;
    background: linear-gradient(96.34deg, #00e9d5 0%, #aab4e9 100%);
  }
`;

const Bizum: React.FC<PageProps> = (props: any) => {
  const {t, language} = useI18next();
  const moneiPayForm = ['es', 'ca'].includes(language) ? moneiPayFormEs : moneiPayFormEn;
  const {data} = props;
  const place: Place = {
    rating: data.googlePlacesPlace.rating,
    user_ratings_total: data.googlePlacesPlace.user_ratings_total,
    reviews: data.allGooglePlacesReview.nodes
  };
  const categories: BlogCategoryType[] = data.prismic.allCategorys.edges.map(mapCategory) || [];
  const popularPosts: BlogPost[] = getPostsFromPrismicEdges({
    posts: data.popularPosts.allPosts.edges,
    categories
  });

  const faqs = [
    {
      header: t('How do I activate the Bizum payment method?'),
      text: t(
        'Please contact support to configure the Bizum payment method in your account. A support agent will respond to your email within 48 hours.'
      ),
      content: (
        <Trans>
          Please{' '}
          <AnchorLink href="https://support.monei.com/hc/es-es/requests/new">
            contact support
          </AnchorLink>{' '}
          to configure the Bizum payment method in your account. A support agent will respond to
          your email within 48 hours.
        </Trans>
      )
    },
    {
      header: t('What are the transaction fees for Bizum?'),
      text: t(
        "Transaction fees for Bizum depend on whether it's an online payment or a physical payment, and your MONEI plan: - With the MONEI X plan (online payments), the platform fee for Bizum payments is 1.34% + €0.34 per successful transaction and the acquiring fee (which gets deducted from your payouts) is €0.17 per successful transaction. - With the MONEI PLUS plan (online payments + payments orchestration), the platform fee for Bizum payments is 1.34% + €0.34 per successful transaction. - With the MONEI Pay plan (physical payments), the platform fee for Bizum payments is 0.9% per successful transaction."
      ),
      content: (
        <Trans>
          Transaction fees for Bizum depend on whether it's an online payment or a physical payment,
          and your <InternalPageLink slug="pricing">MONEI plan:</InternalPageLink> - With the MONEI
          X plan (online payments), the platform fee for Bizum payments is 1.34% + €0.34 per
          successful transaction and the acquiring fee (which gets deducted from your payouts) is
          €0.17 per successful transaction. - With the MONEI PLUS plan (online payments + payments
          orchestration), the platform fee for Bizum payments is 1.34% + €0.34 per successful
          transaction. - With the MONEI Pay plan (physical payments), the platform fee for Bizum
          payments is 0.9% per successful transaction.
        </Trans>
      )
    },
    {
      header: t('For how long can I refund Bizum transactions?'),
      text: t(
        'Bizum transactions can be refunded within 365 days after the initial transaction has been processed.'
      ),
      content: (
        <Trans>
          Bizum transactions can be refunded within 365 days after the initial transaction has been
          processed.
        </Trans>
      )
    },
    {
      header: t('Where is Bizum available?'),
      text: t(
        'Bizum is a Spanish peer-to-peer (P2P) payment app that customers can connect with their Spanish bank account (IBAN) to send payments to friends or complete online purchases.'
      ),
      content: (
        <Trans>
          Bizum is a Spanish peer-to-peer (P2P) payment app that customers can connect with their
          Spanish bank account (IBAN) to send payments to friends or complete online purchases.
        </Trans>
      )
    },
    {
      header: t('How do Bizum payments work for consumers?'),
      text: t(
        'After your customer fills out their details on the payment page they will go through the following payment flow: - A waiting screen will load - The customer will get a push notification from their banking app - Then the customer can complete the payment in their banking app'
      ),
      content: (
        <Trans>
          After your customer fills out their details on the payment page they will go through the
          following payment flow: - A waiting screen will load - The customer will get a push
          notification from their banking app - Then the customer can complete the payment in their
          banking app
        </Trans>
      )
    },
    {
      header: t('Is there a Bizum consumer spending limit?'),
      text: t(
        'Yes. We apply a maximum spending limit for Bizum payments made from consumers to businesses. - Consumers can spend up to €1500 per Bizum transaction. Any amount beyond this threshold per transaction will be rejected. - Consumers can spend a maximum of €4000 per month online with Bizum.'
      ),
      content: (
        <Trans>
          Yes. We apply a maximum spending limit for Bizum payments made from consumers to
          businesses. - Consumers can spend up to €1500 per Bizum transaction. Any amount beyond
          this threshold per transaction will be rejected. - Consumers can spend a maximum of €4000
          per month online with Bizum.
        </Trans>
      )
    },
    {
      header: t('What do Bizum transaction types "SALE" and "AUTH" mean?'),
      text: t(
        "Bizum has two different transaction types that MONEI has no control over. - SALE refers to the automatic capture transaction type and generally there is no friction. - AUTH refers to the pre-authorization transaction type and doesn’t ensure we can capture the payment amount. From Bizum's side, the amount is not frozen and the customer's balance is not checked. So a successful transaction is not guaranteed."
      ),
      content: (
        <Trans>
          Bizum has two different transaction types that MONEI has no control over. - SALE refers to
          the automatic capture transaction type and generally there is{' '}
          <AnchorLink href="https://monei.com/blog/frictionless-payments/">no friction.</AnchorLink>{' '}
          - AUTH refers to the pre-authorization transaction type and doesn’t ensure we can capture
          the payment amount. From Bizum's side, the amount is not frozen and the customer's balance
          is not checked. So a successful transaction is not guaranteed.
        </Trans>
      )
    }
  ];

  return (
    <>
      <SEO
        title={t('Accept Bizum Payments Online & In-Person')}
        description={t(
          'Increase sales and customer satisfaction by accepting Bizum online, in-store, at your restaurant, or anywhere your business takes you. Start here ››'
        )}
      />
      <IndexBackground
        sx={{
          left: {all: '108%', xl: '119%', lg: '1325px'},
          top: {all: '-100%', xl: '-108%', lg: '-125%'},
          height: {all: '2150px', lg: '1960px'},
          width: {all: '1530px'},
          transform: {
            all: 'rotate(-58deg) skew(0deg, 17deg)',
            xl: 'rotate(-58deg) skew(2deg,33deg)',
            lg: 'rotate(-58deg) skew(12deg,12deg)'
          }
        }}>
        <Content>
          <Section>
            <MainTitleContent>
              <SectionHeader underline tagName="h1">
                <Trans>Accept Bizum payments</Trans>
              </SectionHeader>
              <Trans parent="p">
                Bizum’s mobile payment solution is the preferred payment method among millions of
                shoppers in Spain. It’s also fast, secure, and contactless, leading to an improved
                customer experience and higher conversion rates. Use MONEI to easily accept Bizum
                online or in person from your phone.
              </Trans>
              <SectionActions>
                <SignUpButton variant="light" style={{marginRight: 15}}>
                  <Trans>Open an Account</Trans>
                </SignUpButton>
                <ContactSalesButton />
              </SectionActions>
            </MainTitleContent>
            <MainTitleImg
              src={bizum_monei}
              alt="MONEI payment gateway"
              title="MONEI payment gateway"
            />
          </Section>
        </Content>
      </IndexBackground>

      <Content style={{marginTop: '40px'}}>
        <Section centered textAlign="center" column>
          <SectionHeader>
            <Trans>
              Benefits of accepting Bizum online, at your brick-and-mortar store, restaurant, or on
              the go with MONEI
            </Trans>
          </SectionHeader>
          <CardsContainer style={{marginTop: '60px'}}>
            <Card>
              <CardImage src={like} alt="" style={{top: -50, width: '110px'}} />
              <CardWrapper>
                <Trans>
                  Reach millions of users by accepting Spain’s most used{' '}
                  <BlogLink slug="local-payment-methods">local payment method</BlogLink>{' '}
                </Trans>
              </CardWrapper>
            </Card>
            <Card>
              <CardImage src={coinstack} alt="" style={{top: -47, width: '100px'}} />
              <CardWrapper>
                <Trans>
                  <AnchorLink href="https://docs.monei.com/docs/payment-methods/bizum/">
                    Add Bizum
                  </AnchorLink>{' '}
                  to your e-commerce store at no extra cost
                </Trans>
              </CardWrapper>
            </Card>
            <Card>
              <CardImage src={cart} alt="" style={{width: '90px', top: -65}} />
              <CardWrapper>
                <Trans>Increase conversions with a shorter payment flow</Trans>
              </CardWrapper>
            </Card>
            <Card>
              <CardImage src={click} alt="" style={{width: '100px', top: -40}} />
              <CardWrapper>
                <Trans>Receive payments instantly — funds are available in real-time</Trans>
              </CardWrapper>
            </Card>
            <Card>
              <CardImage src={mobile_payment} alt="" style={{width: '130px', top: -50}} />
              <CardWrapper>
                <Trans parent="p">
                  Save money on bulky POS hardware —{' '}
                  <InternalPageLink slug="monei-pay">
                    accept Bizum payments from your phone
                  </InternalPageLink>
                </Trans>
              </CardWrapper>
            </Card>
            <Card>
              <CardImage src={person} alt="" style={{width: '100px', top: -65}} />
              <CardWrapper>
                <Trans parent="p">
                  Reduce customer queues with faster, contactless in-person payments
                </Trans>
              </CardWrapper>
            </Card>
          </CardsContainer>
          <SectionActions>
            <SignUpButton variant="dark" style={{marginRight: 15}}>
              <Trans>Discover MONEI</Trans>
            </SignUpButton>
          </SectionActions>
        </Section>
      </Content>

      <Background>
        <Content>
          <Section centered>
            <SectionImage
              src={moneiPayForm}
              alt="MONEI Payment Gateway"
              title="MONEI Payment Gateway"
              height={650}
              className="hide-on-mobile"
            />
            <div>
              <SectionHeader>
                <Trans>How does Bizum work for customers?</Trans>
              </SectionHeader>
              <Trans parent="p">
                Bizum users can send and receive money instantly or pay for purchases using their
                smartphones. Customers can link their IBAN to their Bizum account to quickly
                complete purchases online and off, without ever sharing personal details with
                merchants.
              </Trans>
              <Trans parent="p">
                Once a customer has linked their bank account to their Bizum profile, it’s available
                for all future purchases, making the payment process faster and more convenient{' '}
                <InternalPageLink slug="omnichannel-payments-platform">
                  across sales channels
                </InternalPageLink>
                .
              </Trans>
              <Bold>
                <Trans parent="p">How Bizum online payments work:</Trans>
              </Bold>
              <List>
                <Trans parent="li">
                  MONEI automatically detects if the customer is located in Spain and shows the
                  Bizum button on the payment page
                </Trans>
                <Trans parent="li">Customer clicks the Bizum button at checkout</Trans>
                <Trans parent="li">Customer gets a push notification from their banking app</Trans>
                <Trans parent="li">Customer confirms the payment in their banking app</Trans>
              </List>
              <Bold>
                <Trans parent="p">How Bizum physical payments work:</Trans>
              </Bold>
              <List>
                <Trans parent="li">
                  You create a{' '}
                  <SupportLink slug="/articles/4417709618193">digital QR payment</SupportLink> or{' '}
                  <SupportLink slug="/articles/12253203762449">payment request</SupportLink>
                </Trans>
                <Trans parent="li">
                  Customers scans the QR code or you enter their mobile number for payment requests
                </Trans>
                <Trans parent="li">
                  Customer selects Bizum on the mobile payment page (QR code payment)
                </Trans>
                <Trans parent="li">Customer gets a push notification from their banking app</Trans>
                <Trans parent="li">Customer confirms the payment in their banking app</Trans>
              </List>
              <SectionActions>
                <SignUpButton variant="dark">
                  <Trans>Get MONEI Now</Trans>
                </SignUpButton>
              </SectionActions>
            </div>
          </Section>
        </Content>
      </Background>
      <Content>
        <Section centered column style={{maxWidth: '940px', margin: 'auto', textAlign: 'center'}}>
          <div>
            <SectionHeader underline>
              <Trans>Accept Bizum payments with MONEI</Trans>
            </SectionHeader>
            <Trans parent="p">
              <Bold>Accept Bizum online</Bold>
            </Trans>
            <Trans parent="p">
              Use a simple plugin to{' '}
              <AnchorLink href="https://docs.monei.com/docs/payment-methods/bizum/">
                add Bizum
              </AnchorLink>{' '}
              to all major e-commerce platforms or use our{' '}
              <AnchorLink href="https://docs.monei.com/api/#tag/Payments">Payments API</AnchorLink>{' '}
              to integrate with your custom-built website.
            </Trans>

            <Trans parent="p">
              <Bold>Accept Bizum in person</Bold>
            </Trans>
            <Trans parent="p">
              Download the <InternalPageLink slug="monei-pay">MONEI Pay</InternalPageLink> app (
              <AndroidDownloadLink>Android</AndroidDownloadLink> or{' '}
              <IosDownloadLink>iOS</IosDownloadLink>) to take Bizum payments from your phone and
              view online and physical transaction history from anywhere.
            </Trans>
            <SectionActions>
              <SignUpButton variant="dark">
                <Trans>Open an Account</Trans>
              </SignUpButton>
            </SectionActions>
          </div>
        </Section>
      </Content>
      <ReviewsBackground style={{marginBottom: '100px'}}>
        <div style={{display: 'flex', justifyContent: 'center', width: '100%'}}>
          <SectionHeader underline sx={{textAlign: 'center'}}>
            <Trans>What MONEI merchants are saying</Trans>
          </SectionHeader>
        </div>
        <GoogleReviews place={place} />
      </ReviewsBackground>
      <Content>
        <Partners />
      </Content>

      <Content>
        <Section centered column>
          <FaqsSection faqs={faqs} title={<Trans>Bizum FAQs</Trans>} />
        </Section>
      </Content>
      {popularPosts.length > 0 && (
        <>
          <Content>
            <Section sx={{paddingBottom: '0px'}}>
              <div style={{display: 'flex', justifyContent: 'center', width: '100%'}}>
                <SectionHeader underline sx={{textAlign: 'center'}}>
                  <Trans>Latest news about Bizum payments</Trans>
                </SectionHeader>
              </div>
            </Section>
          </Content>
          <RelatedBlogPosts posts={popularPosts} />
        </>
      )}
    </>
  );
};

export default Bizum;

export const query = graphql`
  query ($language: String!, $prismicLang: String) {
    locales: allLocale(filter: {ns: {in: ["common", "bizum"]}, language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    googlePlacesPlace {
      user_ratings_total
      rating
    }
    allGooglePlacesReview(sort: {fields: rating, order: DESC}, filter: {rating: {gt: 2}}) {
      nodes {
        id
        rating
        text
        relative_time_description
        profile_photo_url
        author_name
      }
    }
    prismic: prismic {
      allCategorys(lang: $prismicLang) {
        edges {
          node {
            title
            _meta {
              uid
              lang
            }
            order
          }
        }
      }
    }
    popularPosts: prismic {
      allPosts(sortBy: meta_lastPublicationDate_DESC, tags: "Bizum", lang: $prismicLang, first: 4) {
        edges {
          node {
            title
            image
            summary
            _meta {
              uid
            }
          }
        }
      }
    }
  }
`;
